$sidebar-bg-color: #BB4D00;
$sidebar-color: #FBEEE3;
$highlight-color: #FFFFFF;
$icon-bg-color: rgba(8, 4, 4, 0.05);
$icon-size: 55px;
$sidebar-collapsed-width: 92px;

@import '~react-pro-sidebar/dist/scss/styles.scss';

#root {
    height: 100%;
}

.layout {
   background-color: #FBEEE3;
   display: flex;
   height: 100%; 
}

#sidebar {
    position: relative;
    display: flex;
}

#sidebar .closemenu {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FBEEE3;
    border-radius: 50%;
    font-size: 17px;
    padding-top: 15px;
    cursor: pointer;
  }

#sidebar .pro-sidebar {
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.image-container {
    display: flex;
    height: 5vh;
    align-items: center;
    justify-content: center;
}

.library-wrapper {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.book-modal {
    min-width: 35%;
}

.btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #BB4D00;
    color: #FBEEE3;
    text-align: center;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
}

.normal-text {
    color: black;
    text-decoration: none;
}

@media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }
}

.grow { 
    -moz-osx-font-smoothing: grayscale; 
    -webkit-backface-visibility: hidden; 
    backface-visibility: hidden; 
    -webkit-transform: translateZ( 0 ); 
    transform: translateZ( 0 ); 
    transition: -webkit-transform .25s ease-out; 
    transition: transform .25s ease-out; 
    transition: transform .25s ease-out, -webkit-transform .25s ease-out; 
}

.grow:hover, .grow:focus { 
    -webkit-transform: scale( 1.05 ); 
    transform: scale( 1.05 ); 
}

.grow:active { 
    -webkit-transform: scale( .90 ); 
    transform: scale( .90 ); 
}